import actionTypes from '../actionTypes';

const selectLocalization = (payload) => ({
    type: actionTypes.LOCALIZATION,
    payload
})

const selectLanguage = (lang) => async (dispatch) => {
    dispatch(selectLocalization(lang))
}

const setLocalization = (payload) => ({
    type: actionTypes.SET_LOCALIZATION,
    payload
})

export default {
    selectLocalization,
    selectLanguage,
    setLocalization
}