const paths = {
    consentRoute: "/care/:launchType/vve/:region/:visitType",
    launchTypes: ['DIRECT', 'INTEGRATEDWEB', 'INTEGRATEDMOBILE'],
    expiredLink: "/expiredLink",
    invalidURL: "/invalidURL",
    systemUnavailable: "/system-unavailable",
    appLauncher: "/app-launcher",
    joinVideoVisit: "/join",
    preferredName: "/preferredName"
}

export default paths;