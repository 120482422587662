import actionTypes from "../actionTypes";

const initialState = {
	isLoading: true,
	validateConfig: {},
	error: null,
};

const ValidateReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.FETCH_VALIDATE_START:
			return {
				...state,
				isLoading: true
			};
		case actionTypes.FETCH_VALIDATE_SUCCESS:
			return {
				...state,
				isLoading: false,
				validateConfig: payload
			};
		case actionTypes.FETCH_CONFIG_FAILED:
			return {
				...state,
				isLoading: false,
			};
		case actionTypes.FETCH_VALIDATE_FAILED:
			return {
				...state,
				isLoading: false,
				error: payload
			};
		default:
			return state;
	}
};

export default ValidateReducer;
