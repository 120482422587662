import React, { useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import GlobalConfig from '../../shared/GlobalConfig';
import routePaths from "../../shared/paths.config";
import actions from "../../store/actions/validateAction";
import translations from '../../translations';
import Button from '../Button/Button';
import SessionModal from "../SessionModal/SessionModal";

const PreferredName = () => {

    const { firstName, lastName, preferredName } = useSelector(state => state.userDetails, shallowEqual);
    const userName = firstName + ' ' + lastName;

    const [name, setName] = useState(preferredName || userName);
    const { config: { preferredNameFeature } } = useSelector((state) => state?.appConfig, shallowEqual);
    const dispatch = useDispatch();
    const inputRef = useRef();

    const { lang } = useSelector(state => state?.localization);
    const translation = translations[lang];

    const navigate = useNavigate();

    const onConfirm = () => {
      navigate(routePaths.joinVideoVisit);
    };

    const onNameChange = (e) => {
      setName(e.target.value);
      dispatch(actions.setPrefferedName(e.target.value));
    };

    const onClear = () => {
      setName("");
      dispatch(actions.setPrefferedName(""));
      inputRef.current.focus();
    };

    const disabled = !name;
    const buttonClass = disabled ? 'vve-preferred-name-disabled' : 'vve-preferred-name';

    let errorMessage = "";
    if(!name) {
        errorMessage = translation.YOUR_NAME_REQ;
    } else if (name.length === preferredNameFeature.maxLength) {
        errorMessage = translation.YOUR_NAME_MAX_LENGTH.replace('<maxLength>', preferredNameFeature.maxLength);
    }

    return (
        <>
            <div className="vve-choose-name-container">
                <h1 className="vve-title">{translation.JOIN_MEETING}</h1>
                <p className="vve-caution">{translation.CAUTION_TXT}</p>
                <div className="vve-name-field-container">
                    <label for="vve-name">{translation.YOUR_NAME_TXT}</label>
                    <div className={!name ? "vve-name-input textBoxInlineError" : "vve-name-input"}>
                        <input id="vve-name" ref={inputRef} className="vve-input" value={name} onChange={onNameChange} maxLength={preferredNameFeature.maxLength} tabIndex="0" />
                        {name && name.length > 0 ? (<div className="vve-clear-container">
                            <button type="button" className="vve-clear" data-testid="clear-btn" onClick={onClear} aria-label={GlobalConfig.CLEAR} aria-role="button" tabIndex="0"></button>
                            </div>) : null}
                    </div>
                    {errorMessage && <div className="vve-error-message" role="Alert" aria-label={errorMessage} tabIndex="0">
                        <div role="Alert" aria-live="assertive">{errorMessage}</div>
                    </div>}
                </div>

                <div className="vve-button-div" data-testid="confirm-btn">
                    <Button
                        modal=""
                        disabled={disabled}
                        tabIndex="0"
                        refValue=""
                        analyticsName=""
                        onClick={onConfirm}
                        class={buttonClass}
                        dataTestId="confirm-button"
                    >
                        {translation.CONFIRM_BTN}
                    </Button>
                </div>
            </div>
            <SessionModal />
        </>
    );
}

export default PreferredName;