import actionTypes from "../actionTypes";

const initialState = {
	joinURL: "",
    meetingID: "",
    meetingPwd: "",
	zoomSDKKey: "",
	zoomSDkSignature: ""
};

const MeetingDetailsReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.SET_MEETING_URL:
			return {
				...state,
				joinURL: payload || ""
			};
		case actionTypes.FETCH_VALIDATE_SUCCESS:
			return {
				...state,
				joinURL: payload.meetingInfo.joinURL,
				meetingID: payload.meetingInfo.meetingID,
                meetingPwd: payload.meetingInfo.password,
				zoomSDKKey: payload.meetingInfo.zoomSDKKey,
				zoomSDkSignature: payload.meetingInfo.zoomSDkSignature
			}
		default:
			return state;
	}
};

export default MeetingDetailsReducer;
