import actionTypes from "../actionTypes";
import { postConsentApi } from "../../service/consent.service";
import { navigateOnDecline, openExternalLinkTab } from "../../helper/commonUtils";
import setErrorAction from "./errorActions";
import GlobalConfig from "../../shared/GlobalConfig";
import { prepareConsentPayload } from "../../helper/abridgeUtils";

const setAgreementUrl = (data) => {
  return {
    type: actionTypes.SET_MEETING_URL,
    payload: data,
  };
};

const setShowModal = (val) => {
  return {
    type: actionTypes.SET_SHOW_MODAL,
    payload: val,
  };
};

const postConsentAction = (validateConfigData, status) => async (dispatch) => {
  dispatch({ type: actionTypes.FETCH_POST_CONSENT_START });
  try {
    const { language, meetingInfo, showLauncher } = validateConfigData;
    const response = await postConsentApi(validateConfigData, status);
    if (response?.status === 200) {
      if (status === GlobalConfig.ACCEPT && (response?.data?.status === GlobalConfig.CONSENT_SUCCESS)) {
        dispatch(setAgreementUrl(meetingInfo?.joinURL));
        if (!showLauncher) {
          const openZoomLink = meetingInfo?.joinURL;
          dispatch({ type: actionTypes.FETCH_POST_CONSENT_START });
          openExternalLinkTab(openZoomLink);
        }
      } else if (status === GlobalConfig.DECLINE) {
        const navigationURL = navigateOnDecline(language);
        openExternalLinkTab(navigationURL);
      }
    }
  } catch (err) {
    if (err && err.response && err.response.data && err.response.data.errors) {
      const errorData = err.response.data.errors;
      if (errorData.length > 0) {
        dispatch(setErrorAction.setErrorHandler(errorData[0]));
        return errorData[0];
      } else {
        dispatch(setErrorAction.setErrorHandler(errorData));
      }
      return errorData;
    } else {
      const error = { ...GlobalConfig.DEFAULT_ERROR_JSON };
      error.errors.code = err?.response?.status || err?.code || 500;
      dispatch(setErrorAction.setErrorHandler(error.errors));
      return error.errors;
    }
  }
};

const submitAbridgeConsentAction = ({ambntlistngConsentStatus, validateConfigData, vveConsentStatus}) => async (dispatch) => {
  dispatch({ type: actionTypes.FETCH_POST_CONSENT_START });
  try {
    const payload = prepareConsentPayload({ambntlistngConsentStatus, validateConfigData});
    const { status: httpStatus, data: { status: submitStatus } }  = await postConsentApi(payload, vveConsentStatus);
    if(httpStatus===200 && submitStatus===GlobalConfig.CONSENT_SUCCESS) {
      const { meetingInfo: { joinURL = null } } = payload;
      openExternalLinkTab(joinURL);
    }
  }
  catch(exception) {
    const defaultError = {...GlobalConfig.DEFAULT_ERROR_JSON.errors, code: "500"}
    dispatch(setErrorAction.setErrorHandler(defaultError));
  }
};

export default {
  setAgreementUrl,
  setShowModal,
  postConsentAction,
  submitAbridgeConsentAction
};
