import { applyMiddleware, createStore, compose } from "redux";
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session'
import thunk from "redux-thunk";
import RootReducer from "./reducers/RootReducer";
import APIConfigMiddleware from './middleware/apiConfigMiddleware';

const persistConfig = {
	key: 'root',
	storage: storageSession,
	whitelist: ['ConfigReducer', 'MeetingDetailsReducer', 'ZoomDetailsReducer', 'DeviceDetailsReducer','LocalizationReducer']
  }

const configureStore = () => {
	const middlewares = [thunk,APIConfigMiddleware];
	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
	const enhancers = composeEnhancers(applyMiddleware(...middlewares));
	const persistedReducer = persistReducer(persistConfig, RootReducer());
	const store = createStore(persistedReducer, enhancers);
	const persistor = persistStore(store)

	return {Store: store, persistor};
};

export default configureStore;