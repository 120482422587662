import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
import App from "./App";
import { Store, persistor } from "./storeConfig";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";

window.process = {
  env: {
    NODE_ENV: "development",
  },
};

const vveLibParams = window.__vveLibParams || null;
const appRootId = vveLibParams ? vveLibParams.targetDivId : "vve-root";

const container = document.getElementById(appRootId);
const root = createRoot(container);

root.render(
	<React.StrictMode>
		<Provider store={Store}>
			<PersistGate loading={null} persistor={persistor}>
        		<App libParam={vveLibParams} />
			</PersistGate>
		</Provider>
	</React.StrictMode>
);

serviceWorker.unregister();
