import React from 'react';
import PropTypes from 'prop-types';

const Button = (props) => {

    let additionalClass = '';
    if (props.modal) {
        additionalClass += 'modal-button';
    }
    let analyticsName = props.children;
    if (props.analyticsName) {
        analyticsName = props.analyticsName;
    }
    let disabledBtn = props.disabled;
    if (disabledBtn === undefined) {
        disabledBtn = false;
    }
    let tabIndexValue = props.tabIndex;
    if (tabIndexValue === undefined) {
        tabIndexValue = '0';
    }
    let dataTestId = props.dataTestId;
    if(!dataTestId) {
        dataTestId = '';
    }
    return (
        <button id={props.id} className={['button', additionalClass, props.class].join(' ')}
            onClick={props.onClick}
            data-analytics-type='button'
            data-analytics-click={analyticsName}
            data-modal-trigger={props.modal}
            disabled={disabledBtn}
            tabIndex={tabIndexValue}
            data-testid={dataTestId}
            // ref={props.refValue || ''}
        >
            {props.children}
        </button>
    );
}

Button.propTypes = {
    analyticsName: PropTypes.any,
    children: PropTypes.any,
    class: PropTypes.any,
    onClick: PropTypes.func,
    modal: PropTypes.any,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    tabIndex: PropTypes.any,
    refValue: PropTypes.any
};

export default Button;