import actionTypes from '../actionTypes';

const setError = (data) => {
  return {
    type: actionTypes.SET_ERROR,
    payload: data
  };
};

const setErrorHandler = (data) => {
  return {
    type: actionTypes.SET_ERROR_HANDLER,
    payload: data
  };
};

const setErrorHandlerOff = () => {
  return {
    type: actionTypes.SET_ERROR_HANDLER_OFF
  };
};

export default {
  setError,
  setErrorHandler,
  setErrorHandlerOff
};