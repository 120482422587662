import actionTypes from "../actionTypes";

const initialState = {
	joinURL: "",
	showModal: false,
	isLoading: false,
};

const AgreementReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.SET_MEETING_URL:
			return {
				...state,
				joinURL: payload,
				isLoading: false
			};
		case actionTypes.SET_SHOW_MODAL:
			return {
				...state,
				showModal: payload
			}
		case actionTypes.FETCH_POST_CONSENT_START:
			return {
				...state,
				isLoading: true
			};
		default:
			return state;
	}
};

export default AgreementReducer;
