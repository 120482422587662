import actionTypes from '../actionTypes';

const initialState = {
    zoomSDK: false
};

const FeatureFlagsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ZOOM_SDK_FLAG:
            return {
                ...state,
                zoomSDK: action.payload
            };
        default:
            return state;
    }
};

export default FeatureFlagsReducer;