import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from "react-redux";
import { navigateOnDecline, openExternalLinkTab } from '../../helper/commonUtils';
import translations from '../../translations';
import Button from '../Button/Button';
import Modal from "../Modal/Modal";

const SessionModal = () => {
    const [showSessionModal, setShowSessionModal] = useState(false);
    const { lang } = useSelector(state => state.localization);
    const { config: { sessionModalTimeout } } = useSelector((state) => state?.appConfig, shallowEqual);
    const translation = translations[lang];

    useEffect(() => {
        let sessionTimer;
        if (sessionModalTimeout?.enabled) {
            sessionTimer = setTimeout(() => {
                setShowSessionModal(true);
                clearTimeout(sessionTimer);
            }, sessionModalTimeout.timeout);
        }
        return () => {
            if (sessionTimer) {
                clearTimeout(sessionTimer);
            }
        }

    });

    const openModal = () => {
        setShowSessionModal(true);
    };

    const closeModal = () => {
        openExternalLinkTab(navigateOnDecline(lang));
    };

    if (!showSessionModal || !sessionModalTimeout.enabled) return null;

    const footerProps = (
        <div className="vve-button-div">
            <Button
                modal=""
                disabled={false}
                tabIndex="0"
                refValue=""
                analyticsName=""
                onClick={closeModal}
                class="vve-modal-agree"
                id="vve-modal-agree"
            >
                {translation.OK}
            </Button>
        </div>
    );

    return (
        <Modal
            id="vve-Modal"
            footerProps={footerProps}
            className="vveModal"
            openModal={openModal}
            backdrop="static"
        >
            <>
                <div className="vve-modal-header">
                    {translation.SESSION_TIMED_OUT_MODAL_HEADER}
                </div>
                <div className="vve-modal-body">
                    {translation.SESSION_TIMED_OUT_MODAL_BODY}
                </div>
            </>
        </Modal>
    )
};

export default SessionModal;
