import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AgreementPage from "./AgreementPage/AgreementPage";
import Loader from "./Loader/Loader";
import pathConfig from '../shared/paths.config';

const { launchTypes, invalidURL } = pathConfig;

const UserConsent = () => {
  const navigate = useNavigate();

  const configLoading = useSelector((state) => state.appConfig.isLoading);
  const { launchType } = useSelector((state) => state.appConfig);
  const validateLoading = useSelector((state) => state.appValidate.isLoading);
  const isLoading = configLoading || validateLoading;
  const isValidLaunchType = launchTypes.indexOf(launchType) > -1;
  
  useEffect(() => {
    if (!isValidLaunchType) {
      navigate(invalidURL);
    }
  }, [])

  if (isLoading) {
    return <Loader showSpinner={isLoading} />;
  }

  return <AgreementPage />;
};

export default UserConsent;
