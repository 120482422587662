import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation, matchPath, useNavigate, Navigate } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import UserConsent from '../components/UserConsent';
import { getParamsForFetchConfig } from "../service/config.service";
import { getQueryString } from '../helper/commonUtils';
import actions from "../store/actions/configAction";
import ErrorPage from '../components/ErrorPage/ErrorPage';
import routePaths from '../shared/paths.config';
import validateActions from '../store/actions/validateAction';
import FooterSection from '../container/Footer/FooterSection';
import { ErrorHandler } from "../components/ErrorHandler/ErrorHandler";
import AppLauncher from '../components/AppLauncher/AppLauncher';
import JoinVideoVisit from '../components/JoinVideoVisit/JoinVideoVisit';
import PreferredName from '../components/PreferredName/PreferredName';
import localizationActions from '../store/actions/localizationActions';
import Layout from '../container/Layout';
import Loader from '../components/Loader/Loader';

const { consentRoute, expiredLink, invalidURL, systemUnavailable, appLauncher, joinVideoVisit, preferredName } = routePaths;

const AppRouter = ({ libParam }) => {

  const dispatch = useDispatch();
  const currentLocation = useLocation();
  const [isSavedParams, setIsSavedParams] = useState(false);
  const { visitType, region, querystring, launchType, config: { tempToken } } = useSelector((state) => state?.appConfig, shallowEqual);
  const navigate = useNavigate();
  const match = matchPath(consentRoute, currentLocation.pathname);
  const pathname = currentLocation.pathname;
  const getLaunchType = pathname.split('/').at(2);
  const configLoading = useSelector(state => state.appConfig.isLoading);
  const validateLoading = useSelector(state => state.appValidate.isLoading);
  const consentLoading = useSelector(state => state.agreementConfig.isLoading);
  const isLoading = configLoading || validateLoading || consentLoading;

  const params = window.location.search;
  const qs = getQueryString(params, getLaunchType);

  useEffect(() => {
    if (match !== null) {
      const href = window.location.href;
      const routeParams = match?.params;
      const fetchConfigParams = getParamsForFetchConfig(qs, href, getLaunchType, routeParams.region, routeParams.visitType);

      if (fetchConfigParams !== null) {
        dispatch(actions.saveParamsForFetchConfig(fetchConfigParams, navigate));
        dispatch(localizationActions.setLocalization(true));
      }
    } else {
      dispatch(localizationActions.setLocalization(false));
    }
    setIsSavedParams(true);
  }, [currentLocation]);

  /* Accessing from External App */
  useEffect(() => {
    dispatch(actions.setVveLibParams(libParam));
    const href = libParam?.myChartFDIURL;
    if (href) {
      const pathnameLink = href.split('?')[0].split('/');
      const pathnameLen = pathnameLink.length;
      const launchTypeCheck = pathnameLink[pathnameLen - 3];
      const regionDetails = pathnameLink[pathnameLen - 2];
      const visitTypeDetails = pathnameLink[pathnameLen - 1];
      const qsDetails = getQueryString(href, launchTypeCheck);

      const fetchConfigParams = getParamsForFetchConfig(qsDetails, href, launchTypeCheck, regionDetails, visitTypeDetails);
      if (fetchConfigParams !== null) {
        dispatch(actions.saveParamsForFetchConfig(fetchConfigParams, navigate));
        dispatch(localizationActions.selectLanguage({
          lang: libParam?.languagePreference,
          labelName: '',
        }));
      }
    }
  }, [libParam])

  useEffect(() => {
    if (launchType && visitType && region && isSavedParams) {
      const paramsPayload = { launchType: launchType, visitType: visitType, region: region }
      dispatch(actions.fetchConfigStart(paramsPayload, navigate));
    }
  }, [dispatch, visitType, region, isSavedParams]);

  useEffect(() => {
    const isTokenAvailable = Object.keys(tempToken).length > 0;
    if (isTokenAvailable) {
      dispatch(validateActions.fetchValidateCallStart());
      const paramsPayload = { launchType, visitType, region }
      const validatePayload = { ...paramsPayload, querystring }
      dispatch(validateActions.fetchValidateStart(validatePayload, navigate));
    }
  }, [Object.keys(tempToken).length > 0])


  return (
    <main className="main-agreement">
      <ErrorHandler />
      <Loader showSpinner={isLoading} />
      {!isLoading && <Routes>
        <Route element={<Layout loading={isLoading} />}>
          <Route path={consentRoute} element={<UserConsent />} />
          <Route path={appLauncher} element={<AppLauncher />} />
          <Route path={expiredLink} element={<ErrorPage />} />
          <Route path={invalidURL} element={<ErrorPage errorType={'invalidURL'} />} />
          <Route path={systemUnavailable} element={<ErrorPage errorType={'systemUnavailable'} />} />
          <Route path="*" element={<Navigate to={invalidURL} />} />
        </Route>
        <Route path={joinVideoVisit} element={<JoinVideoVisit />} />
        <Route path={preferredName} element={<PreferredName />} />
      </Routes>}
      <FooterSection />
    </main>
  )
}

export default AppRouter;