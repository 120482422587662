import React from "react";

const Loader = ({showSpinner}) => {

  if(!showSpinner) return null;

  return (
    <div className='loader'>
    </div>
  );
};

export default Loader;