import apiClient from '../helper/apiClient';

export const postConsentApi = async (validateConfigData, status) => {
    const httpClient = apiClient.getInstance();
    const url = apiClient.getConsentUrl();
    const formData = {
        firstName: validateConfigData?.user?.firstName,
        lastName: validateConfigData?.user?.lastName,
        consent: status,
        region: validateConfigData?.meetingInfo?.region,
        zoomMeetingId: validateConfigData?.meetingInfo?.meetingID.toString(),
        zoomUid: validateConfigData?.user?.uid,
        visitType: validateConfigData?.meetingInfo?.visitType,
        launchType: validateConfigData?.meetingInfo?.launchType,
    }


    if(validateConfigData?.ambntlistngConsentStatus) {
        formData.ambntlistngConsent = validateConfigData.ambntlistngConsentStatus;
    }

    return httpClient.post(url, formData);
}