import actionTypes from "../actionTypes";

const defaultState = {
  labelName: "Español",
  lang: "en",
  show: false,
};

const LocalizationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.LOCALIZATION:
      return {
        ...state,
        labelName: action.payload.labelName,
        lang: action.payload.lang,
      };
    case actionTypes.SET_LOCALIZATION:
      return {
        ...state,
        show: action.payload,
      };
    default:
      return state;
  }
};

export default LocalizationReducer;