import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import translations from "../../translations";
import Button from "../Button/Button";
import { openExternalLinkTab } from '../../helper/commonUtils';
import routePaths from "../../shared/paths.config";
import GlobalConfig from "../../shared/GlobalConfig";
import SessionModal from "../SessionModal/SessionModal";

const AppLauncher = () => {
    const navigate = useNavigate();
    const { lang } = useSelector(state => state.localization);
    const launchJoinURL = useSelector(state => state.agreementConfig.joinURL);
    const { type: deviceType, os: deviceOs, launcherInfo } = useSelector(state => state.deviceDetails, shallowEqual);
    const { zoomSDK } = useSelector(state => state.featureFlags);
    const { region, myChartFDIURL, config: { preferredNameFeature } } = useSelector((state) => state?.appConfig, shallowEqual);
    const translation = translations[lang];
    
    const onContinueBrowser = () => {
        if (preferredNameFeature?.enabled) {
        navigate(routePaths.preferredName);
        } else {
            navigate(routePaths.joinVideoVisit);
        }
    }

    const launchZoomApp = () => {
        openExternalLinkTab(launchJoinURL);
    }

    const launchKpMobileApp = () => {
        const launchKpMobileAppLink = region === GlobalConfig.WA_REGION ? launcherInfo?.kpwa[deviceOs]?.appLaunch : launcherInfo?.kpMobile[deviceOs]?.appLaunch;
        let link = launchKpMobileAppLink.replace("{myChartFDIURL}", myChartFDIURL);
        openExternalLinkTab(link);
    }
    const getMobileDownloadLink = () => region === GlobalConfig.WA_REGION ? launcherInfo?.kpwa[deviceOs]?.appDownload : launcherInfo?.kpMobile[deviceOs]?.appDownload;
    const kpMobileAppDownloadLink = (deviceType === GlobalConfig.MOBILE) ? getMobileDownloadLink() : null;
    const zoomAppDownloadLink = (deviceType === GlobalConfig.DESKTOP) ? launcherInfo?.zoomDownload : null;

    const webLauncher = (
        <div className="vve-web-launcher-page-body">
            <h1 className="vve-tab-title">{translation.LAUNCHER_TITLE}</h1>

            <div className="vve-button-div">
                <Button
                    modal=""
                    disabled={false}
                    tabIndex="0"
                    refValue=""
                    analyticsName=""
                    onClick={launchZoomApp}
                    class="vve-launch-zoom"
                >
                    {translation.LAUNCH_ZOOM_BTN}
                </Button>
                <p className="vve-download-text">{translation.LAUNCHER_RECOMMENDED_TEXT} {translation.LAUNCHER_NOT_DOWNLOAD_TEXT} <a className="vve-anchorlink-text" href={zoomAppDownloadLink} target="_blank" aria-label={translation.ADA_DOWNLOAD_TEXT}>{translation.LAUNCHER_DOWNLOAD_TEXT}</a></p>
            </div>

            {zoomSDK && <div className="vve-button-div">
                <Button
                    modal=""
                    disabled={false}
                    tabIndex="0"
                    refValue=""
                    analyticsName=""
                    onClick={onContinueBrowser}
                    class="vve-continue-browser"
                    id="vve-continue-browser-btn"
                >
                    {translation.CONTINUE_BROWSER_BTN}
                </Button>
            </div>
            }

        </div>
    )

    const mobileLauncher = (
        <div className="vve-mobile-launcher-page-body">
            <h1>{translation.LAUNCHER_TITLE}</h1>

            <div className="vve-button-div">
                <Button
                    modal=""
                    disabled={false}
                    tabIndex="0"
                    refValue=""
                    analyticsName=""
                    onClick={launchKpMobileApp}
                    class="vve-launch-kp-app"
                >
                    {translation.LAUNCH_KP_MOBILE_BTN}
                </Button>
                <p className="vve-download-text"><span className="vve-medium">{translation.LAUNCHER_RECOMMENDED_TEXT}</span><span className="vve-book"> {translation.LAUNCHER_NOT_DOWNLOAD_TEXT}<br/>{translation.KP_MOBILE__DOWNLOAD_BELOW_TEXT}</span></p>
            </div>

            <div className="vve-button-div">
                <Button
                    modal=""
                    disabled={false}
                    tabIndex="0"
                    refValue=""
                    analyticsName=""
                    onClick={launchZoomApp}
                    class="vve-launch-zoom-app"
                    id="vve-launch-zoom-app-btn"
                >
                    {translation.LAUNCH_ZOOM_APP_BTN}
                </Button>
                <p className="vve-download-text">{translation.MOBILE_LAUNCHER_ALREADY_DOWNLOAD_TEXT}</p>
            </div>
            <div className="vve-kp-mobile-download-container">
                <h2>{translation.KP_MOBILE_DOWNLOAD_TEXT}</h2>
                <a className="vve-anchorlink-text" href={kpMobileAppDownloadLink} target="_blank" aria-label={translation.ADA_DOWNLOAD_TEXT}>{translation.KP_MOBILE_DOWNLOAD_BELOW_TEXT}</a>
                {zoomSDK && <h2>{translation.EXPERIENCE_ISSUES_TEXT}</h2> }
                {zoomSDK && <a class="vve-anchorlink-text" role="link" tabIndex="0" onClick={onContinueBrowser} target="_blank">{translation.CONTINUE_BROWSER_TEXT}</a>}
            </div>
        </div>
    );

    const renderAppLauncher = () => {
        switch (deviceType) {
            case GlobalConfig.DESKTOP:
                return webLauncher;
            case GlobalConfig.MOBILE:
                return mobileLauncher;
            default:
                navigate(routePaths.systemUnavailable);

        }
    }

    return (
        <>
            <div className={`vve-launcher-bg ${deviceType === GlobalConfig.MOBILE ? 'vve-launcher-bg-mobile' : ''}`}></div>
            <div className={`vve-launcher-logo ${deviceType === GlobalConfig.MOBILE ? 'vve-launcher-logo-mobile' : ''}`}></div>
            {renderAppLauncher()}
            <SessionModal />
        </>
    )
}

export default AppLauncher;