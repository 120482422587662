import actionTypes from "../actionTypes";
import { getValidateApi } from "../../service/validate.service";
import routePaths from '../../shared/paths.config';

const fetchValidateCallStart = () => (dispatch) => {
	dispatch( { type: actionTypes.FETCH_VALIDATE_START });
}

const fetchValidateStart = (paramsPayload, navigate) => async (dispatch) => {
	try{
		const { launchType, region, visitType, querystring } = paramsPayload;
		const res = await getValidateApi(querystring);
		if(res?.errors?.length > 0) {
			throw res;
		}
		res.meetingInfo.launchType = launchType;
		res.meetingInfo.region = region;
		res.meetingInfo.visitType = visitType;
		res.meetingInfo.querystring = querystring;
		dispatch(fetchValidateSuccess(res));
	}
	catch(err) {
		let errors = err?.response?.data ? err?.response?.data?.errors : err?.errors;
		if(errors?.[0].code === "2001") {
			navigate(routePaths.invalidURL);
		} else if(errors?.[0].code === "2002" || errors?.[0].code === "2003" || errors?.[0].code === "2006" || errors?.[0].code === "2007"  || errors?.[0].code === "2009") {
			navigate(routePaths.expiredLink);
		} else {
			navigate(routePaths.systemUnavailable);
		}
		dispatch(fetchValidateError(err));
	}
};

const fetchValidateSuccess = (validateResponse) => ({
	type: actionTypes.FETCH_VALIDATE_SUCCESS,
	payload: validateResponse,
});

const fetchValidateError = (errorMessage) => ({
	type: actionTypes.FETCH_VALIDATE_FAILED,
	payload: errorMessage,
});

const setPrefferedName = (payload) => ({
	type: actionTypes.SET_PREFFEREDNAME,
	payload: payload
})

export default {
	fetchValidateCallStart,
	fetchValidateStart,
	fetchValidateSuccess,
	fetchValidateError,
	setPrefferedName
};