import React from 'react';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import GlobalConfig from "../../shared/GlobalConfig";
import translations from "../../translations";
import { navigateOnDecline, openExternalLinkTab } from '../../helper/commonUtils';
import action from '../../store/actions/errorActions';

export const ErrorHandler = () => {
    const { errorModal, errorResponse } = useSelector((state) => state?.errorValue, shallowEqual);
    const { lang } = useSelector(state => state?.localization);
    let title = "";
    let message = "";

    const dispatch = useDispatch();

    const handleCloseModal = () => {
        dispatch(action.setErrorHandlerOff());
        const navigationURL = navigateOnDecline(lang);
        openExternalLinkTab(navigationURL);
    };
    
    if(errorModal){
        const translation = translations[lang].ErrorConstants;
        if(errorResponse && errorResponse.code){
            title = translation[errorResponse.code]?.title || translation.default.title;
            message = translation[errorResponse.code]?.message || translation.default.message;
        }
    }

    if (errorModal) {
        const footerProps = (
            <div className="vve-button-div">
                <Button
                    modal=""
                    tabIndex="0"
                    onClick={handleCloseModal}
                    class="vve-modal-agree error-modal-agree"
                >
                    {GlobalConfig.OK}
                </Button>
            </div>
        );

        return (
            <Modal
                id="vve-error-Modal"
                footerProps={footerProps}
                openModal={errorModal}
                className="vveModal"
                backdrop="static"
            >
                <>
                    <div className="vve-modal-header error-modal-header">
                        {title}
                    </div>
                    <div className="vve-modal-body error-modal-body">
                        {message}
                    </div>
                </>
            </Modal>
        );
    }
    return null;
}