import actionTypes from '../actionTypes';

const initialState = {
    errorModal: false,
    errorResponse: {}
};

const ErrorHandlerReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ERROR_HANDLER:
            return {
                errorModal: true,
                errorResponse: action.payload
            };
        case actionTypes.SET_ERROR_HANDLER_OFF:
            return {
                errorModal: false,
                errorResponse: {}
            };
        default:
            return state;
    }
};

export default ErrorHandlerReducer;