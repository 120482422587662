import actionTypes from "../actionTypes";

const initialState = {
	isLoading: true,
	config: {
		bffServices: {},
		tempToken: {}
	},
	error: null,
	visitType: null,
	region: null,
	myChartFDIURL: null,
	launchType: null,
	vveLibParams: {}
};

const ConfigReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.SAVE_PARAMS_FOR_FETCH_CONFIG:
			return {
				...state,
				visitType: payload.visitType,
				region: payload.region,
				querystring: payload.querystring,
				myChartFDIURL: payload.myChartFDIURL,
				launchType: payload.launchType,
			};
		case actionTypes.FETCH_CONFIG_START:
			return {
				...state,
				isLoading: true
			};
		case actionTypes.FETCH_CONFIG_SUCCESS:
			const launchtype = payload.launchType;
			delete payload.launchType;
			return {
				...state,
				isLoading: false,
				config: payload,
				launchType: launchtype
			};
		case actionTypes.FETCH_CONFIG_FAILED:
			return {
				...state,
				isLoading: false,
				error: payload
			};
		case actionTypes.SET_VVE_LIBPARAMS:
			return{
				...state,
				vveLibParams: payload
			}
		default:
			return state;
	}
};

export default ConfigReducer;
