import React from "react";
import { Outlet } from "react-router-dom";
import HeaderSection from "./Header/HeaderSection";

const Layout = ({ loading }) => {
  return (
    <>
      {!loading && <HeaderSection />}
      <Outlet />
    </>
  );
};

export default Layout;
