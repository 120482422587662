import React from "react";
import { BrowserRouter, MemoryRouter } from "react-router-dom";
import AppRouter from "./Router/appRouter";
import routePaths from "./shared/paths.config";

const App = ({ libParam }) => {
  const { preferredName } = routePaths;

  return (
    <>
      {libParam ? (
        <MemoryRouter initialEntries={[preferredName]}>
          <AppRouter libParam={libParam} />
        </MemoryRouter>
      ) : (
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
      )}
    </>
  );
};

export default App;
