import React from 'react';
import { useSelector } from 'react-redux';
import translations from '../../translations';

const LanguageSelector = ({
    options = {},
    onClick,
    defaultText = '',
}) => {

    const lang = useSelector(state => state?.localization?.lang);
    const translation = translations[lang];

    return (
        <div>
            <a className="vve-language-select" role="link" aria-label={translation["SWITCH_LANG_ARIA"]} onClick={onClick} tabIndex={0}>
            {options[defaultText]}
        </a>
        </div>
    )
}

export default LanguageSelector;