import actionTypes from "../actionTypes";

const initialState = {
	firstName: "",
    lastName: "",
    uid: "",
	preferredName: ""
};

const UserInfoReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.FETCH_VALIDATE_SUCCESS:
			const userData = payload.user || initialState;
			return {
				...state,
				...userData
			}
		case actionTypes.SET_PREFFEREDNAME:
			return {
				...state,
				preferredName: payload
			}
		default:
			return state;
	}
};

export default UserInfoReducer;
