import React, { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { navigateOnDecline, zoomMeetingEvents } from "../../helper/commonUtils";
import routePaths from "../../shared/paths.config";

const JoinVideoVisit = () => {
    const { meetingID, meetingPwd, joinURL, zoomSDKKey, zoomSDkSignature } = useSelector(state => state.meetingInfo, shallowEqual);
    const validateLoading = useSelector(state => state.appValidate.isLoading);
    const { firstName, lastName, preferredName } = useSelector(state => state.userDetails, shallowEqual);
    const { vveLibParams } = useSelector((state) => state?.appConfig, shallowEqual);
    const { lang } = useSelector((state) => state?.localization, shallowEqual)
    const { url, option, disableInvite } = useSelector((state) => state?.zoomSdkDetails, shallowEqual);
    const navigate = useNavigate();
    const userName = preferredName ? preferredName : firstName + ' ' + lastName;
    let isZoomLoad = false;
    let ZoomMtg;

    const addZoomStyles = async () => {
        await import('@zoomus/websdk/dist/css/bootstrap.css');
        await import('@zoomus/websdk/dist/css/react-select.css');
    }

    const zoomLeaveUrl = (language) => {
        let navigationURL;
        if (vveLibParams && vveLibParams?.redirectURL)
            navigationURL = vveLibParams.redirectURL
        else
            navigationURL = navigateOnDecline(language);
        return navigationURL
    }
    
    const loadZoom = async () => {
        try {
            addZoomStyles();
            const ZoomSDK = await import('@zoomus/websdk');
            ZoomMtg = ZoomSDK.ZoomMtg;
            const languageSelected = lang === "en" ? 'en-US' : 'es-ES';
            ZoomMtg.setZoomJSLib(url, option);
            ZoomMtg.preLoadWasm();
            ZoomMtg.prepareWebSDK();
            await ZoomMtg.i18n.load(languageSelected);

            const zoomMeetingDOM = document.getElementById('zmmtg-root');
            zoomMeetingDOM.style.display = 'block';

            const styles = document.getElementsByTagName('style')
            for (let index = 0; index < styles.length; index++) {
                if (styles[index].disabled) {
                    styles[index].disabled = false;
                }
            }

            ZoomMtg.init({
                leaveUrl: zoomLeaveUrl(lang),
                disableInvite,
                success: (success) => {
                    console.log('init success', success);
                    ZoomMtg.join({
                        signature: zoomSDkSignature,
                        meetingNumber: parseInt(meetingID),
                        userName: userName,
                        sdkKey: zoomSDKKey,
                        passWord: meetingPwd,
                        success: (successStatus) => {
                            console.log('join success', successStatus);
                        },
                        error: (error) => {
                            console.log('join error', error);
                        }
                    });
                },
                error: (error) => {
                    console.log('init, error', error);
                }
            });
            zoomMeetingEvents(ZoomMtg, vveLibParams?.subscribe2Conference?.subscribe2Status);
        }
        catch (err) {
            console.log("Join Page", err);
        }
    }

    useEffect(() => {
        if (!validateLoading) {
            if ((vveLibParams && meetingID !== "") || (meetingID !== "" && joinURL !== "")) {
                if (!isZoomLoad) {
                    isZoomLoad = true;
                    loadZoom();
                }
            }
            else {
                navigate(routePaths.systemUnavailable)
            }
        }
    }, []);

    useEffect(() => {
        return () => {
            if (!vveLibParams) {
                const zoomMeetingDOM = document.getElementById('zmmtg-root');
                if (zoomMeetingDOM) {
                    zoomMeetingDOM.style.display = 'none';
                    zoomMeetingDOM.style.height = '0';
                    zoomMeetingDOM.style.width = '0';
                    zoomMeetingDOM.style.position = 'relative';
                }
                const zoomScripts = document.getElementsByTagName('script');
                for (let index = 0; index < zoomScripts.length; index++) {
                    let theScript = zoomScripts[index];

                    if ((theScript.src).includes(url)) {
                        theScript.parentNode.removeChild(theScript);
                    }
                }

                const styles = document.getElementsByTagName('style');
                for (let index = 0; index < styles.length; index++) {
                    if (index !== 0) {
                        styles[index].disabled = true;
                    }
                }
            }
        };
    }, []);

    return (<React.Fragment />);
}

export default JoinVideoVisit;