import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session'
import ConfigReducer from "./ConfigReducer";
import ValidateReducer from "./ValidateReducer";
import AgreementReducer from "./AgreementReducer";
import ErrorHandlerReducer from "./ErrorHandlerReducer";
import LocalizationReducer from "./LocalizationReducer";
import MeetingDetailsReducer from "./MeetingDetailsReducer";
import UserInfoReducer from "./UserInfoReducer";
import DeviceDetailsReducer from "./DeviceDetailsReducer";
import FeatureFlagsReducer from "./FeatureFlagsReducer";
import ZoomDetailsReducer from "./ZoomDetailsReducer";

const persistAppConfig = {
	key: 'appConfig',
	storage: storageSession,
	whitelist: ['visitType', 'region', 'querystring', 'flowType']
  }

const persistMeetingInfoConfig = {
	key: 'meetingInfo',
	storage: storageSession
  }

const persistZoomSDKDetailsConfig = {
	key: 'zoomSdkDetails',
	storage: storageSession
  }

const persistDeviceDetailsConfig = {
	key: 'deviceDetails',
	storage: storageSession
  }

const persistLocalizationDetailsConfig = {
	key: 'localizationDetails',
	storage: storageSession
  }

const persistUserInfoConfig = {
	key: 'userInfo',
	storage: storageSession
}

const persistAgreementDetailsConfig = {
	key: 'agreementConfig',
	storage: storageSession
  }

const RootReducer = () =>
	combineReducers({
		appConfig: persistReducer(persistAppConfig, ConfigReducer),
		appValidate: ValidateReducer,
		agreementConfig: persistReducer(persistAgreementDetailsConfig, AgreementReducer),
		errorValue: ErrorHandlerReducer,
		localization: persistReducer(persistLocalizationDetailsConfig, LocalizationReducer),
		meetingInfo: persistReducer(persistMeetingInfoConfig, MeetingDetailsReducer),
		userDetails: persistReducer(persistUserInfoConfig, UserInfoReducer),
		deviceDetails: persistReducer(persistDeviceDetailsConfig, DeviceDetailsReducer),
		featureFlags: FeatureFlagsReducer,
		zoomSdkDetails: persistReducer(persistZoomSDKDetailsConfig, ZoomDetailsReducer),
	});

export default RootReducer;