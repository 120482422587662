import React from 'react';
import GlobalConfig from "../../shared/GlobalConfig";
import { useSelector} from "react-redux";
import { navigateOnDecline, openExternalLinkTab } from "../../helper/commonUtils";
import Button from "../Button/Button";
import translations from '../../translations';

const ErrorPage = ({ errorType = 'expiredLink' }) => {

  const { lang } = useSelector(state => state?.localization);

  let textKey = "EXPIRED_TEXT";
  let subTextKey = "EXPIRED_SUBTEXT";

  if (errorType == 'invalidURL') {
    textKey = "INVALID_URL_TEXT";
    subTextKey = "INVALID_URL_SUBTEXT";
  } else if (errorType == 'systemUnavailable') {
    textKey = "SYSTEMUNAVAILABLE_MODAL_HEADER";
    subTextKey = "SYSTEMUNAVAILABLE_MODAL_BODY";
  }

  const onClickHandler = () => {
    const navigationURL = navigateOnDecline(lang);
    openExternalLinkTab(navigationURL);
  }

  return (
        <div className="vve-error-page-container">
          <div className='vve-error-logo'></div>
          <div className='expired-block'>
            <div className="block">
              <div className="link">{translations['en'][textKey]}</div>
              <div className="linkText">{translations['en'][subTextKey]}</div>
            </div>
            <div className='line'></div>
            <div className="block">
              <div className="link">{translations['es'][textKey]}</div>
              <div className="linkText">{translations['es'][subTextKey]}</div>
            </div>
          </div>
          <div className="vve-button-div">
            <Button
              id="kpLinkBtn"
              modal=""
              //disabled=""
              tabIndex="0"
              refValue=""
              analyticsName=""
              onClick={onClickHandler}
              class="vve-modal-agree link"
            >
              <div className='btn-txt'>
                <div className="underline">{GlobalConfig.KP_LINK_TEXT}</div>
                <div className="arrow"></div>
              </div>
            </Button>
          </div>
        </div>
  )
}

export default ErrorPage;